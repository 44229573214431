<template>
    <child-layout :title="fullTitle">
        <ag-grid-server-side
            v-if="columnDefs.length && schoolYear && lea"
            :id="tableId"
            :key="title"
            :server-side-datasource="serverSideDatasource"
            server-side-store-type="partial"
            :column-defs="columnDefs"
            :set-grid-api="setGridApi"
            :btn-export-action="btnExportAction"
        >
        </ag-grid-server-side>
        <v-skeleton-loader v-else type="table" />
    </child-layout>
</template>

<script>
import ChildLayout from '@/components/layout/ChildLayout'
import AgGridServerSide from '@/components/table/AgGridServerSide'
import graphQLDataSource from '@/helpers/ag-grid/datasource/graphql'
import { mapGetters } from 'vuex'
import { lowerCase } from '@/helpers/shared'
import { parse } from 'json2csv'
import { saveFile } from '@/helpers/file'

export default {
    name: 'TableViewer',
    components: { ChildLayout, AgGridServerSide },
    props: {
        apiAction: {
            type: String,
            default: '',
        },
        apiGetter: {
            type: String,
            default: '',
        },
        namespace: {
            type: String,
            default: '',
        },
        columns: {
            type: Function,
            default: () => ({}),
        },
        title: {
            type: String,
            default: '',
        },
    },
    data: () => ({ columnDefs: [], gricdApi: {}, lastParams: {} }),
    computed: {
        fetching() {
            return this.$store[this.namespace].fetching
        },
        tableId() {
            return `${lowerCase(this.title.replace(/\s+/g, ''))}TableViewer`
        },
        ...mapGetters({
            lea: 'getDistrictLea',
            schoolYear: 'schoolYear/getSelected',
        }),
        fullTitle() {
            let title = this.title
            if (this.schoolYear) {
                title += ` in ${this.schoolYear}`
            }

            return title
        },
    },
    watch: {
        // refresh columns when prop is updated.
        async columns() {
            const mod = await this.columns()
            this.columnDefs = mod.default
            this.gridApi.refreshCells({ force: true })
        },
    },
    async beforeMount() {
        /**
         * Before the component is mounted we want to get the module which defines the available columns for the
         * table
         */
        const mod = await this.columns()
        this.columnDefs = mod.default
    },
    methods: {
        serverSideDatasource: function () {
            const action = `${this.namespace}/get`
            const fetchRows = async (queryPayload) => {
                queryPayload.lea = this.lea
                queryPayload.schoolYear = this.schoolYear
                this.lastParams = queryPayload

                const filters = this.gridApi.getFilterModel()
                queryPayload = Object.entries(filters).reduce(
                    (acc, [curr, { filter, type }]) => {
                        acc[curr] = filters[curr].filter
                        switch (type) {
                            case 'contains':
                                acc[curr] = `%${filter}%`
                                break
                            case 'startsWith':
                                acc[curr] = `${filter}%`
                                break
                            case 'endsWith':
                                acc[curr] = `%${filter}`
                                break
                            default:
                                acc[curr] = filter
                        }

                        return acc
                    },
                    { ...queryPayload }
                )
                await this.$store.dispatch(action, queryPayload)
            }

            return {
                getRows: graphQLDataSource.partialStore(fetchRows, this.namespace).getRows,
            }
        },
        setGridApi: function (api) {
            this.gridApi = api
        },
        /**
         * There's an oprotunity to further optimize this by using Streams. I tried using this feature on a couple larger
         * districts and this implimentation doesn't have a lot of overhead once query time is accounted for.
         *
         * Converting this to use streams would require a change to the api, and to have the new endpoint available in this
         * function.
         */
        btnExportAction: async function () {
            const action = `${this.namespace}/get`
            const params = {
                ...this.lastParams,
            }
            delete params['pagination[limit]']
            delete params['pagination[offset]']
            const result = await this.$store.dispatch(action, params)

            const arr = Object.values(result.data)[0]
            const csv = parse(arr)
            saveFile(`${this.namespace}.csv`, csv)
        },
    },
}
</script>
